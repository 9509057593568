//*==================================================================*
//*                                                                  *
//* EDER GUIMARAES RODRIGUES - 25/03/2022                            *
//*------------------------------------------------------------------*
//* CADASTRO.TSX - TELA DE CADASTRO DA APLICAÇÃO                     *
//*                                                                  *
//*------------------------------------------------------------------*
//*    IMPORTS......:                                                *
import React from 'react';
import axios from 'axios';
import BeatLoader from "react-spinners/BeatLoader";
import { ToastContainer, toast } from 'react-toastify';
//*                                                                  *
//*------------------------------------------------------------------*
//*    COMP. IMPORTS:                                                *
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
//*                                                                  *
//*------------------------------------------------------------------*
//*    FORM FIELDS..:                                                *
import Customer_Name from './form_fields/Customer_Name';
import Customer_CPF from './form_fields/Customer_CPF';
import Customer_RG from './form_fields/Customer_RG';
import Customer_Birthdate from './form_fields/Customer_Birthdate';
import Customer_Phone from './form_fields/Customer_Phone';
import Customer_Email from './form_fields/Customer_Email';
import Address_CEP from './form_fields/Address_CEP';
import Address_Address from './form_fields/Address_Address';
import Address_Number from './form_fields/Address_Number';
import Address_Neighbor from './form_fields/Address_Neighbor';
import Address_City from './form_fields/Address_City';
import Address_State from './form_fields/Address_State';
import Address_Complement from './form_fields/Address_Complement';
import Payment_Number from './form_fields/Payment_Number';
import Payment_Validity from './form_fields/Payment_Validity';
import Payment_Name from './form_fields/Payment_Name';
import Payment_CVV from './form_fields/Payment_CVV';
import Payment_Parcel from './form_fields/Payment_Parcel';
//*                                                                  *
//*------------------------------------------------------------------*
//*    CSS IMPORTS..:                                                *
import '../../assets/css/Style.css';
import 'react-toastify/dist/ReactToastify.css';
//*                                                                  *
//*------------------------------------------------------------------*
//*    GIFS IMPORTS.:                                                *
import success from '../../assets/gifs/success.gif';
//*                                                                  *
//*------------------------------------------------------------------*
//*    JSON IMPORTS:                                                 *
import texts from '../../assets/json/texts.json';
//*                                                                  *
//*==================================================================*
//*                                                                  *
class Cadastro extends React.Component<Record<string, never>, {
    customer_nameIsValid: boolean,
    customer_cpfIsValid: boolean,
    customer_birthIsValid: boolean,
    customer_phoneIsValid: boolean,
    customer_emailIsValid: boolean,
    address_cepIsValid: boolean,
    address_addressIsValid: boolean,
    address_numberIsValid: boolean,
    address_neighborIsValid: boolean,
    address_cityIsValid: boolean,
    address_stateIsValid: boolean,
    payment_address_numberIsValid: boolean,
    payment_validityIsValid: boolean,
    payment_nameIsValid: boolean,
    payment_cvvIsValid: boolean,
    address: string,
    neighbor: string,
    city: string,
    state: string,
    termChecked: boolean,
    formSubmited: boolean,
    formValidated: boolean
}> {
    constructor(props: any) {
        super(props);
        this.state = {
            customer_nameIsValid: false,
            customer_cpfIsValid: false,
            customer_birthIsValid: false,
            customer_phoneIsValid: false,
            customer_emailIsValid: false,
            address_cepIsValid: false,
            address_addressIsValid: false,
            address_numberIsValid: false,
            address_neighborIsValid: false,
            address_cityIsValid: false,
            address_stateIsValid: false,
            payment_address_numberIsValid: false,
            payment_validityIsValid: false,
            payment_nameIsValid: false,
            payment_cvvIsValid: false,
            address: '',
            neighbor: '',
            city: '',
            state: '',
            termChecked: false,
            formSubmited: false,
            formValidated: false,
        }
    }
    //*--------------------------------------------------------------*
    //* CHECK ZIP RESPONSE                                           *
    //*--------------------------------------------------------------*
    zipResponse(e: any) {
        if (e.address) this.setState({ address_addressIsValid: true });
        if (e.neighbor) this.setState({ address_neighborIsValid: true });
        if (e.city) this.setState({ address_cityIsValid: true });
        if (e.uf) this.setState({ address_stateIsValid: true });
        this.setState({
            address: e.address,
            neighbor: e.neighbor,
            city: e.city,
            state: e.uf
        });
    }
    //*--------------------------------------------------------------*
    //* SUBMIT FORM FUNCTION                                         *
    //*--------------------------------------------------------------*
    async submitForm(e: any) {
        e.preventDefault();
        const formFieldsValidation = [
            this.state.customer_nameIsValid, this.state.customer_cpfIsValid,
            this.state.customer_birthIsValid, this.state.customer_phoneIsValid,
            this.state.customer_emailIsValid, this.state.address_cepIsValid,
            this.state.address_addressIsValid, this.state.address_numberIsValid,
            this.state.address_neighborIsValid, this.state.address_cityIsValid,
            this.state.address_stateIsValid, this.state.payment_address_numberIsValid,
            this.state.payment_validityIsValid, this.state.payment_nameIsValid,
            this.state.payment_cvvIsValid
        ]
        if (formFieldsValidation.indexOf(false) >= 0) {
            toast.error('Reveja as informações dos campos destacados em vermelho.');
        } else {
            this.setState({ formSubmited: true });

            const data = new FormData(document.getElementById('cad__form') as HTMLFormElement);
            const json: any = {};

            Array.from(data.entries()).forEach(([key, value]) => {
                json[key] = value;
            });

            const jsonFormatted = this.formatJSON(json);
            console.log(jsonFormatted)

            try {
                await axios.post('https://dripix.mibrasil.com.br/api/payment',
                    // await axios.post('http://10.0.10.14:3000/api/payment',
                    jsonFormatted
                );
                this.setState({ formValidated: true });
            }
            catch (error: any) {
                this.setState({
                    formSubmited: false,
                    formValidated: false
                });

                if (error.response.data.message === 'Cliente já possui uma assinatura ativa. Não é possível realizar outra assinatura para o mesmo cliente.') {
                    toast.error('Cliente já possui uma assinatura ativa. Não é possível realizar outra assinatura para o mesmo cliente.');
                } else {
                    if (error.response.data.message[0].substr(0, error.response.data.message[0].indexOf('.')) === 'customer') {
                        toast.error('Erro nos dados de CADASTRO, por favor, reveja as informações e reenvie o formulário.');
                    } else {
                        if (error.response.data.message[0].substr(0, error.response.data.message[0].indexOf('.')) === 'payment') {
                            toast.error('Erro nos dados de PAGAMENTO, por favor, reveja as informações e reenvie o formulário.');
                        } else {
                            toast.error('Valide os dados do cartão.');
                        }
                    }
                }
            }
        }
    }
    //*--------------------------------------------------------------*
    //* PREPARE JSON TO API                                          *
    //*--------------------------------------------------------------*
    formatJSON(json: any) {
        const regexCustomer = /[^xX0-9]+/g;
        const regexMobile = /[^0-9]+/g;
        const regexAddress = /[^a-zA-ZçàèìòùÀÈÌÒÙáéíóúýÇÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸ ]/g;
        const regexPayment = /[^0-9]+/g;
        return {
            "termo": this.state.termChecked,
            "items": [
                {
                    "description": "Assinatura Dripix",
                    "quantity": 1
                }
            ],
            "customer": {
                "name": json.name.substring(0, 60),
                "rg": json.rg.replace(regexCustomer, ''),
                "email": json.email.substring(0, 60),
                "type": "individual",
                "document": json.cpf.replace(regexCustomer, ''),
                "documentType": json.cpf.replace(regexCustomer, '').length === 11 ? 'CPF' : 'CNPJ',
                "birthdate": json.birth.substring(6, 10) + '-' + json.birth.substring(3, 5) + '-' + json.birth.substring(0, 2) + 'T00:00:00Z',
                "phones": {
                    "mobile_phone": {
                        "country_code": 55,
                        "area_code": parseInt(json.phone.replace(regexMobile, '').substring(0, 2)),
                        "number": parseInt(json.phone.replace(regexMobile, '').substring(2, json.phone.replace(regexMobile, '').length))
                    }
                },
                "address": {
                    "number": json.number,
                    "country": "BR",
                    "street": json.address.replace(regexAddress, ''),
                    "neighborhood": json.neighbor.replace(regexAddress, ''),
                    "state": json.state.replace(regexAddress, ''),
                    "zip_code": json.zip.replace(/[^0-9]+/g, ''),
                    "city": json.city.replace(regexAddress, ''),
                    "complement": json.complement.replace(regexAddress, '')
                }
            },
            "payment": {
                "recurrence": true,
                "installments": parseInt(json.credit_parcel),
                "card": {
                    "number": json.credit_no.replace(regexPayment, ''),
                    "holderName": json.credit_name.substring(0, 60),
                    "exp_month": parseInt(json.credit_validity.replace(regexPayment, '').substring(0, 2)),
                    "exp_year": parseInt(json.credit_validity.replace(regexPayment, '').substring(2, 4)),
                    "cvv": json.credit_safe.replace(regexPayment, '')
                }
            }
        }
    }
    //*--------------------------------------------------------------*
    //* MAIN CLASS RENDER                                            *
    //*--------------------------------------------------------------*
    render() {
        //*----------------------------------------------------------*
        //* HTML RETURN                                              *
        //*----------------------------------------------------------*
        return (
            //*------------------------------------------------------*
            //*                                                      *
            //*------------------------------------------------------*
            <section className='viewport'>
                <div style={{ overflow: "hidden" }}>
                    <ToastContainer closeButton={false} />
                </div>
                <div className='app'>
                    <Navbar />
                    <div className='cad__area'>
                        {this.state.formValidated
                            ? <div className='cad__success_gif'>
                                <p className='cad__title'>{texts.success1}</p>
                                <p className='cad__title'>{texts.success2}</p>
                                <img src={success} />
                            </div>
                            : <form id='cad__form'>
                                <p className='cad__title'>{texts.cad__title}</p>
                                <Customer_Name isValid={(e: any) => this.setState({ customer_nameIsValid: e })} />

                                <div className='form__both_in_a_line'>
                                    <Customer_CPF isValid={(e: any) => this.setState({ customer_cpfIsValid: e })} />
                                    <Customer_RG />
                                </div>

                                <div className='form__both_in_a_line'>
                                    <Customer_Birthdate isValid={(e: any) => this.setState({ customer_birthIsValid: e })} />
                                    <Customer_Phone isValid={(e: any) => this.setState({ customer_phoneIsValid: e })} />
                                </div>

                                <Customer_Email isValid={(e: any) => this.setState({ customer_emailIsValid: e })} />

                                <p className='cad__title'>{texts.paymnt__address}</p>
                                <div className='form__both_in_a_line'>
                                    <Address_CEP isValid={(e: any) => this.setState({ address_cepIsValid: e })}
                                        zipValid={(e: any) => this.zipResponse(e)} />
                                    <div className='form__bil-div-48'>
                                        <div className='form__find-cep'>
                                            <a href='https://buscacepinter.correios.com.br/app/endereco/index.php' target='_blank'>{texts.cad__busca_cep}</a>
                                        </div>
                                    </div>
                                </div>

                                {this.state.address_cepIsValid
                                    ? <section className='form__show_address_fields'>
                                        <div className='form__both_in_a_line'>
                                            <Address_Address address={this.state.address} isValid={(e: any) => this.setState({ address_addressIsValid: e })} />
                                            <Address_Number isValid={(e: any) => this.setState({ address_numberIsValid: e })} />
                                        </div>
                                        <div className='form__both_in_a_line'>
                                            <Address_Neighbor neighbor={this.state.neighbor} isValid={(e: any) => this.setState({ address_neighborIsValid: e })} />
                                            <Address_City city={this.state.city} isValid={(e: any) => this.setState({ address_cityIsValid: e })} />
                                        </div>
                                        <div className='form__both_in_a_line'>
                                            <Address_State state={this.state.state} isValid={(e: any) => this.setState({ address_stateIsValid: e })} />
                                            <Address_Complement />
                                        </div>
                                    </section> : <></>}

                                <p className='cad__title'>{texts.paymnt__payment}</p>

                                <div className='form__both_in_a_line'>
                                    <Payment_Number isValid={(e: any) => this.setState({ payment_address_numberIsValid: e })} />
                                    <Payment_Validity isValid={(e: any) => this.setState({ payment_validityIsValid: e })} />
                                </div>

                                <Payment_Name isValid={(e: any) => this.setState({ payment_nameIsValid: e })} />

                                <div className='form__both_in_a_line'>
                                    <Payment_CVV isValid={(e: any) => this.setState({ payment_cvvIsValid: e })} />
                                    <Payment_Parcel />
                                </div>

                                <div className='form__check_term'>
                                    <input className='cad__form_input_check'
                                        type="checkbox"
                                        onChange={(e: any) => {
                                            this.setState({
                                                termChecked: e.currentTarget.checked
                                            })
                                        }} /> Concordo com o <a href='https://dripix.mibrasil.com.br/termo' target={'_blank'} rel='noreferrer' >Termo de Adesão</a>
                                </div>

                                {this.state.termChecked ?
                                    <div className='cad__area_button'>
                                        {this.state.formSubmited
                                            ? <BeatLoader color="#FF6900" size="20px" />
                                            : <button type='submit' onClick={(e) => {
                                                const isFormValid = (document.getElementById('cad__form') as HTMLFormElement).checkValidity();
                                                if (!isFormValid) {
                                                    (document.getElementById('cad__form') as HTMLFormElement).reportValidity();
                                                } else {
                                                    this.submitForm(e)
                                                }
                                            }}> {texts.cad__button} </button>
                                        }
                                    </div> : <></>}
                            </form>
                        }
                    </div>
                    <Footer />
                </div>
            </section>
            //*                                                      *
            //*------------------------------------------------------*
        );
        //*                                                          *
        //*----------------------------------------------------------*
    }
    //*                                                              *
    //*--------------------------------------------------------------*
}
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default Cadastro;
//*                                                                  *
//*==================================================================*            