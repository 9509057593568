//*==================================================================*
//*                                                                  *
//* EDER GUIMARAES RODRIGUES - 25/03/2022                            *
//*------------------------------------------------------------------*
//* PAYMENT_PARCEL.TSX - CAMPO PARCELA                               *
//*                                                                  *
//*------------------------------------------------------------------*
//*    IMPORTS......:                                                *
import React from "react";
//*                                                                  *
//*------------------------------------------------------------------*
//*    CSS IMPORTS..:                                                *
import '../../../assets/css/Style.css';
//*                                                                  *
//*------------------------------------------------------------------*
//*    JSON IMPORTS:                                                 *
import texts from '../../../assets/json/texts.json';
//*                                                                  *
//*==================================================================*
//*                                                                  *
class Payment_Parcel extends React.Component<Record<string, unknown>, {
    credit_parcel: string,
}> {
    constructor(props: any) {
        super(props);
        this.state = {
            credit_parcel: '',
        }
    }
    //*--------------------------------------------------------------*
    //* FUNCTION VALID FIELD                                         *
    //*--------------------------------------------------------------*
    validField(e: any) {
        this.setState({
            credit_parcel: e.target.value,
        });
    }
    //*--------------------------------------------------------------*
    //* MAIN CLASS RENDER                                            *
    //*--------------------------------------------------------------*
    render() {
        //*----------------------------------------------------------*
        //* HTML RETURN                                              *
        //*----------------------------------------------------------*
        return (
            //*------------------------------------------------------*
            //*                                                      *
            <div className='form__bil-div-48'>
                <p className='cad__form_p'>{texts.paymnt__parcel_times}</p>
                <select className='cad__form_input'
                    name='credit_parcel' id='credit_parcel' autoComplete='off'
                    value={this.state.credit_parcel}
                    onChange={(e: any) => this.validField(e)}>
                    <option value={1}>01x R$120,00</option>
                    <option value={2}>02x R$60,00</option>
                    <option value={3}>03x R$40,00</option>
                </select>
            </div >
            //*                                                      *
            //*------------------------------------------------------*
        );
        //*                                                          *
        //*----------------------------------------------------------*
    }
    //*                                                              *
    //*--------------------------------------------------------------*
}
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default Payment_Parcel;
//*                                                                  *
//*==================================================================*   