//*==================================================================*
//*                                                                  *
//* EDER GUIMARAES RODRIGUES - 25/03/2022                            *
//*------------------------------------------------------------------*
//* FOOTER.TSX - FOOTER DA APLICAÇÃO                                 *
//*                                                                  *
//*------------------------------------------------------------------*
//*    IMPORTS......:                                                *
import React from 'react';
import Carousel from 'react-multi-carousel';
//*                                                                  *
//*------------------------------------------------------------------*
//*    CSS IMPORTS..:                                                *
import '../assets/css/Style.css';
import 'react-multi-carousel/lib/styles.css';
//*                                                                  *
//*------------------------------------------------------------------*
//*    ICONS IMPORTS:                                                *
import page1 from '../assets/images/page1.png';
import page2 from '../assets/images/page2.png';
//*                                                                  *
//*==================================================================*
//*                                                                  *
class CarouselDripix extends React.Component<Record<string, never>, Record<string, never>> {
    //*--------------------------------------------------------------*
    //* MAIN CLASS RENDER                                            *
    //*--------------------------------------------------------------*
    render() {
        const responsive = {
            superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 1 },
            desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
            tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
            mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
        };
        const length = [
            "superLargeDesktop", "desktop", "tablet", "mobile"
        ];
        //*----------------------------------------------------------*
        //* HTML RETURN                                              *
        //*----------------------------------------------------------*
        return (
            //*------------------------------------------------------*
            //*                                                      *
            //*------------------------------------------------------*
            <section>
                <Carousel showDots={true} responsive={responsive}
                    autoPlaySpeed={6000} transitionDuration={500}
                    removeArrowOnDeviceType={length}>
                    <div className='carousel__page1'>
                        <div>
                            <img src={page1} />
                        </div>
                    </div>
                    <div className='carousel__page2'>
                        <div>
                            <img src={page2} />
                        </div>
                    </div>
                </Carousel>
            </section>
            //*                                                      *
            //*------------------------------------------------------*
        );
        //*                                                          *
        //*----------------------------------------------------------*
    }
    //*                                                              *
    //*--------------------------------------------------------------*
}
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default CarouselDripix;
//*                                                                  *
//*==================================================================*