//*==================================================================*
//*                                                                  *
//* EDER GUIMARAES RODRIGUES - 25/03/2022                            *
//*------------------------------------------------------------------*
//* PAYMENT_NUMBER.TSX - CAMPO NUMERO                                *
//*                                                                  *
//*------------------------------------------------------------------*
//*    IMPORTS......:                                                *
import React from "react";
//*                                                                  *
//*------------------------------------------------------------------*
//*    CSS IMPORTS..:                                                *
import '../../../assets/css/Style.css';
//*                                                                  *
//*------------------------------------------------------------------*
//*    JSON IMPORTS:                                                 *
import texts from '../../../assets/json/texts.json';
//*                                                                  *
//*==================================================================*
//*                                                                  *
class Payment_Number extends React.Component<
    {
        isValid: (e: boolean) => void,
    }, {
        validCredit_number: boolean,
        credit_number: string,
        flag: string,
    }> {
    constructor(props: any) {
        super(props);
        this.state = {
            validCredit_number: true,
            credit_number: '',
            flag: ''
        }
    }
    //*--------------------------------------------------------------*
    //* FUNCTION KEY PRESS                                           *
    //*--------------------------------------------------------------*
    keyPress(e: any) {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    }
    //*--------------------------------------------------------------*
    //* FUNCTION VALID FIELD                                         *
    //*--------------------------------------------------------------*
    validField(e: any) {
        const numberLength = e.target.value.replace(/[^0-9]+/g, '').length;
        switch (true) {
            case numberLength === 14:
                this.setState({
                    credit_number: e.target.value.replace(/\D/g, "").replace(/^(\d{4})(\d{6})(\d{4})+?$/, "$1 $2 $3"), flag: ''
                })
                break;
            case numberLength === 15:
                this.setState({
                    credit_number: e.target.value.replace(/\D/g, "").replace(/^(\d{4})(\d{6})(\d{5})+?$/, "$1 $2 $3"), flag: ''
                })
                break;
            case numberLength === 16:
                this.setState({
                    credit_number: e.target.value.replace(/\D/g, "").replace(/^(\d{4})(\d{4})(\d{4})(\d{4})+?$/, "$1 $2 $3 $4"), flag: ''
                })
                break;
            case numberLength > 16:
                break;
            default:
                this.setState({ credit_number: e.target.value, flag: '' })
                break;
        }
        if (numberLength < 14) {
            this.setState({ validCredit_number: false });
            this.props.isValid(false);
        } else {
            this.setState({ validCredit_number: true });
            this.props.isValid(true);
        }
        this.checkFlag(e.target.value);
    }
    //*--------------------------------------------------------------*
    //* CHECK CREDIT CARD FLAG                                       *
    //*--------------------------------------------------------------*
    checkFlag(card: any) {
        card = card.replace(/[^0-9]+/g, '');
        const cards: any = {
            visa: /^4[0-9]{12}(?:[0-9]{3})/,
            mastercard: /^5[1-5][0-9]{14}/,
            diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
            amex: /^3[47][0-9]{13}/,
            elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
            hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/
        };
        for (const flag in cards) {
            if (cards[flag].test(card)) {
                this.setState({
                    flag: "/icons/credit-" + flag + ".png"
                })
            }
        }
    }
    //*--------------------------------------------------------------*
    //* MAIN CLASS RENDER                                            *
    //*--------------------------------------------------------------*
    render() {
        //*----------------------------------------------------------*
        //* VALID FIELDS TO CHANGE CSS                               *
        //*----------------------------------------------------------*
        const validLabel: string = this.state.validCredit_number
            ? 'cad__form_p'
            : 'cad__form_p_error'
        const validField: string = this.state.validCredit_number
            ? 'cad__form_input'
            : 'cad__form_input_error'
        //*----------------------------------------------------------*
        //* HTML RETURN                                              *
        //*----------------------------------------------------------*
        return (
            //*------------------------------------------------------*
            //*                                                      *
            <div className='form__bil-div-73'>
                <div className="form__title_flag">
                    <p className={validLabel}>{texts.paymnt__card_number}</p>
                    <img src={this.state.flag} />
                </div>
                <input className={validField} required
                    name='credit_no' id='credit_no' autoComplete='off'
                    value={this.state.credit_number}
                    onPaste={(e: any) => { e.preventDefault(); }}
                    onKeyPress={(e: any) => this.keyPress(e)}
                    onChange={(e: any) => this.validField(e)}
                />
            </div>
            //*                                                      *
            //*------------------------------------------------------*
        );
        //*                                                          *
        //*----------------------------------------------------------*
    }
    //*                                                              *
    //*--------------------------------------------------------------*
}
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default Payment_Number;
//*                                                                  *
//*==================================================================*   