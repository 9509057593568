//*==================================================================*
//*                                                                  *
//* EDER GUIMARAES RODRIGUES - 25/03/2022                            *
//*------------------------------------------------------------------*
//* MENU.TSX - PAGINA MENU                                           *
//*                                                                  *
//*------------------------------------------------------------------*
//*    IMPORTS......:                                                *
import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
//*                                                                  *
//*------------------------------------------------------------------*
//*    CSS IMPORTS..:                                                *
import '../../assets/css/Style.css';
//*                                                                  *
//*------------------------------------------------------------------*
//*    ICONS IMPORTS:                                                *
import logo from '../../assets/icons/logo.png';
import back from '../../assets/icons/back.svg';
//*                                                                  *
//*------------------------------------------------------------------*
//*    JSON IMPORTS:                                                 *
import texts from '../../assets/json/texts.json';
//*                                                                  *
//*==================================================================*
//*                                                                  *
class Menu extends React.Component<Record<string, never>, {
    menu: boolean,
}> {
    constructor(props: any) {
        super(props);
        this.state = {
            menu: true,
        }
    }
    //*--------------------------------------------------------------*
    //* MAIN CLASS RENDER                                            *
    //*--------------------------------------------------------------*
    render() {
        const currentUrl: any = window.location.href.split("?").slice(-1);
        //*----------------------------------------------------------*
        //* HTML RETURN                                              *
        //*----------------------------------------------------------*
        return (
            //*------------------------------------------------------*
            //*                                                      *
            //*------------------------------------------------------*
            <section className='viewport'>
                <div className='app'>
                    <div className='menu__logo'>
                        <img src={logo} />
                    </div>
                    {this.state.menu
                        ? <Link to={{ pathname: "/" + currentUrl[0] }}>
                            <div className='menu__backbar'>
                                <img src={back} />
                                <p>{texts.menu__back_to_menu}</p>
                            </div>
                        </Link>
                        : <div className='menu__pointer menu__backbar' onClick={
                            () => this.setState({
                                menu: !this.state.menu
                            })}>
                            <img src={back} />
                            <p>{texts.menu__back}</p>
                        </div>
                    }
                    {/* {this.state.menu ? */}
                    <div className='menu__links'>
                        <Link to={{ pathname: "/" }}>
                            <p>{texts.menu__about}</p>
                        </Link>
                        <hr />
                        <HashLink smooth to="/#comofunciona">
                            <p>{texts.menu__how_it_works}</p>
                        </HashLink>
                        <hr />
                        {/* <div className='menu__pointer' onClick={
                                () => this.setState({
                                    menu: !this.state.menu
                                })}>
                                <p>{texts.menu__attendance}</p>
                            </div>
                             */}
                        <HashLink smooth to="/#faq">
                            <p>{texts.menu__faq}</p>
                        </HashLink>
                        <hr />
                        <Link to={{ pathname: "/cadastro" }}>
                            <p>{texts.menu__register}</p>
                        </Link>
                    </div>
                    {/* : <div className='menu__links'>
                            {/* <Link to={{ pathname: "/contato" }}>
                                <p>{texts.menu__contact}</p>
                            </Link>
                            <hr /> 
                            <HashLink smooth to="/#faq">
                                <p>{texts.menu__faq}</p>
                            </HashLink>
                        </div>
                    } */}
                </div>
            </section >
            //*                                                      *
            //*------------------------------------------------------*
        );
        //*                                                          *
        //*----------------------------------------------------------*
    }
    //*                                                              *
    //*--------------------------------------------------------------*
}
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default Menu;
//*                                                                  *
//*==================================================================*