//*==================================================================*
//*                                                                  *
//* EDER GUIMARAES RODRIGUES - 25/03/2022                            *
//*------------------------------------------------------------------*
//* ADDRESS_NEIGHBOR.TSX - CAMPO BAIRRO                              *
//*                                                                  *
//*------------------------------------------------------------------*
//*    IMPORTS......:                                                *
import React from "react";
//*                                                                  *
//*------------------------------------------------------------------*
//*    CSS IMPORTS..:                                                *
import '../../../assets/css/Style.css';
//*                                                                  *
//*------------------------------------------------------------------*
//*    JSON IMPORTS:                                                 *
import texts from '../../../assets/json/texts.json';
//*                                                                  *
//*==================================================================*
//*                                                                  *
class Address_Neighbor extends React.Component<
    {
        neighbor: string,
        isValid: (e: boolean) => void,
    }, {
        validNeighbor: boolean,
        neighbor: string,
    }> {
    constructor(props: any) {
        super(props);
        this.state = {
            validNeighbor: true,
            neighbor: '',
        }
    }
    componentDidMount() {
        this.setState({ neighbor: this.props.neighbor })
    }
    //*--------------------------------------------------------------*
    //* FUNCTION VALID FIELD                                         *
    //*--------------------------------------------------------------*
    validField(e: any) {
        this.setState({
            neighbor: e.target.value,
        });
        if (e.target.value.length <= 0) {
            this.setState({ validNeighbor: false })
            this.props.isValid(false);
        } else {
            this.setState({ validNeighbor: true })
            this.props.isValid(true);
        }
    }
    //*--------------------------------------------------------------*
    //* MAIN CLASS RENDER                                            *
    //*--------------------------------------------------------------*
    render() {
        //*----------------------------------------------------------*
        //* VALID FIELDS TO CHANGE CSS                               *
        //*----------------------------------------------------------*
        const validLabel: string = this.state.validNeighbor
            ? 'cad__form_p'
            : 'cad__form_p_error'
        const validField: string = this.state.validNeighbor
            ? 'cad__form_input'
            : 'cad__form_input_error'
        //*----------------------------------------------------------*
        //* HTML RETURN                                              *
        //*----------------------------------------------------------*
        return (
            //*------------------------------------------------------*
            //*                                                      *
            <div className='form__bil-div-48'>
                <p className={validLabel}>{texts.cad__neighbor}</p>
                <input className={validField} required
                    name='neighbor' id='neighbor' autoComplete='off'
                    value={this.state.neighbor}
                    onPaste={(e: any) => { e.preventDefault(); }}
                    onChange={(e: any) => this.validField(e)}
                />
            </div>
            //*                                                      *
            //*------------------------------------------------------*
        );
        //*                                                          *
        //*----------------------------------------------------------*
    }
    //*                                                              *
    //*--------------------------------------------------------------*
}
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default Address_Neighbor;
//*                                                                  *
//*==================================================================*   