//*==================================================================*
//*                                                                  *
//* EDER GUIMARAES RODRIGUES - 25/03/2022                            *
//*------------------------------------------------------------------*
//* NAVBAR.TSX - NAVBAR DA APLICAÇÃO                                 *
//*                                                                  *
//*------------------------------------------------------------------*
//*    IMPORTS......:                                                *
import React from 'react';
import { Link } from 'react-router-dom';
//*                                                                  *
//*------------------------------------------------------------------*
//*    CSS IMPORTS..:                                                *
import '../assets/css/Style.css';
//*                                                                  *
//*------------------------------------------------------------------*
//*    ICONS IMPORTS:                                                *
import bars from '../assets/icons/bars.svg';
import logo from '../assets/icons/logo.png';
//*                                                                  *
//*==================================================================*
//*                                                                  *
class Navbar extends React.Component<Record<string, never>, Record<string, never>> {
    //*--------------------------------------------------------------*
    //* MAIN CLASS RENDER                                            *
    //*--------------------------------------------------------------*
    render() {
        const currentUrl: any = window.location.href.split("/").slice(-1);
        //*----------------------------------------------------------*
        //* HTML RETURN                                              *
        //*----------------------------------------------------------*
        return (
            //*------------------------------------------------------*
            //*                                                      *
            //*------------------------------------------------------*
            <section className='navbar__area'>
                <div className='navbar_bars'>
                    <Link to={{ pathname: "/menu?" + currentUrl[0], }}>
                        <img src={bars} />
                    </Link>
                </div>
                <div>
                    <img src={logo} />
                </div>
            </section >
            //*                                                      *
            //*------------------------------------------------------*
        );
        //*                                                          *
        //*----------------------------------------------------------*
    }
    //*                                                              *
    //*--------------------------------------------------------------*
}
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default Navbar;
//*                                                                  *
//*==================================================================*