//*==================================================================*
//*                                                                  *
//* EDER GUIMARAES RODRIGUES - 25/03/2022                            *
//*------------------------------------------------------------------*
//* CUSTOMER_CPF.TSX - CAMPO CPF                                     *
//*                                                                  *
//*------------------------------------------------------------------*
//*    IMPORTS......:                                                *
import React from "react";
//*                                                                  *
//*------------------------------------------------------------------*
//*    CSS IMPORTS..:                                                *
import '../../../assets/css/Style.css';
//*                                                                  *
//*------------------------------------------------------------------*
//*    JSON IMPORTS:                                                 *
import texts from '../../../assets/json/texts.json';
//*                                                                  *
//*==================================================================*
//*                                                                  *
class Customer_CPF extends React.Component<
    {
        isValid: (e: boolean) => void,
    }, {
        validDoc: boolean,
        doc: string,
    }> {
    constructor(props: any) {
        super(props);
        this.state = {
            validDoc: true,
            doc: '',
        }
    }
    //*--------------------------------------------------------------*
    //* FUNCTION KEY PRESS                                           *
    //*--------------------------------------------------------------*
    keyPress(e: any) {
        if (!/[xX0-9]/.test(e.key)) {
            e.preventDefault();
        }
    }
    //*--------------------------------------------------------------*
    //* FUNCTION VALID FIELD                                         *
    //*--------------------------------------------------------------*
    validField(e: any) {
        const doc = e.target.value.replace(/[^xX0-9]+/g, '');

        this.setState({
            doc: doc.replace(/\W/g, "")
                .replace(/^(\d{3})(\d{3})(\d{3})(\w{2})+?$/, "$1.$2.$3-$4")
        });
        const eArray: number[] = Array.from(String(doc), Number);
        let fisrtValidation = false;
        let secondValidation = false;
        const firstValidatorCode: number = eArray[eArray.length - 2];
        const secondValidatorCode: number = eArray[eArray.length - 1];
        let firstSum = 0;
        for (let i = 0; i < 9; i++) {
            firstSum += (eArray[i] * (10 - i));
        }
        const firstCodeToValidate: number = 11 - (firstSum % 11);
        if ((firstCodeToValidate === firstValidatorCode) ||
            (firstCodeToValidate >= 10 && firstValidatorCode === 0)) {
            fisrtValidation = true;
        }
        let secondSum = 0;
        for (let j = 0; j < 10; j++) {
            secondSum += (eArray[j] * (11 - j));
        }
        const secondCodeToValidate: number = 11 - (secondSum % 11);
        if ((secondCodeToValidate === secondValidatorCode) ||
            (secondCodeToValidate >= 10 && secondValidatorCode === 0)) {
            secondValidation = true;
        }
        if (fisrtValidation === true && secondValidation === true) {
            this.setState({ validDoc: true });
            this.props.isValid(true);
        } else {
            this.setState({ validDoc: false });
            this.props.isValid(false);
        }
    }
    //*--------------------------------------------------------------*
    //* MAIN CLASS RENDER                                            *
    //*--------------------------------------------------------------*
    render() {
        //*----------------------------------------------------------*
        //* VALID FIELDS TO CHANGE CSS                               *
        //*----------------------------------------------------------*
        const validLabel: string = this.state.validDoc
            ? 'cad__form_p'
            : 'cad__form_p_error'
        const validField: string = this.state.validDoc
            ? 'cad__form_input'
            : 'cad__form_input_error'
        //*----------------------------------------------------------*
        //* HTML RETURN                                              *
        //*----------------------------------------------------------*
        return (
            //*------------------------------------------------------*
            //*                                                      *
            <div className='form__bil-div-48'>
                <p className={validLabel}>{texts.cad__cpf}</p>
                <input className={validField} required
                    name='cpf' id='cpf' autoComplete='off'
                    value={this.state.doc}
                    onPaste={(e: any) => { e.preventDefault(); }}
                    onKeyPress={(e: any) => this.keyPress(e)}
                    onChange={(e: any) => this.validField(e)}
                />
            </div>
            //*                                                      *
            //*------------------------------------------------------*
        );
        //*                                                          *
        //*----------------------------------------------------------*
    }
    //*                                                              *
    //*--------------------------------------------------------------*
}
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default Customer_CPF;
//*                                                                  *
//*==================================================================*   