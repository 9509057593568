//*==================================================================*
//*                                                                  *
//* EDER GUIMARAES RODRIGUES - 25/03/2022                            *
//*------------------------------------------------------------------*
//* PAYMENT_NAME.TSX - CAMPO NOME                                    *
//*                                                                  *
//*------------------------------------------------------------------*
//*    IMPORTS......:                                                *
import React from "react";
//*                                                                  *
//*------------------------------------------------------------------*
//*    CSS IMPORTS..:                                                *
import '../../../assets/css/Style.css';
//*                                                                  *
//*------------------------------------------------------------------*
//*    JSON IMPORTS:                                                 *
import texts from '../../../assets/json/texts.json';
//*                                                                  *
//*==================================================================*
//*                                                                  *
class Payment_Name extends React.Component<
    {
        isValid: (e: boolean) => void,
    }, {
        validCredit_name: boolean,
        credit_name: string,
    }> {
    constructor(props: any) {
        super(props);
        this.state = {
            validCredit_name: true,
            credit_name: '',
        }
    }
    //*--------------------------------------------------------------*
    //* FUNCTION KEY PRESS                                           *
    //*--------------------------------------------------------------*
    keyPress(e: any) {
        if (!/[a-zA-Z ]/.test(e.key)) {
            e.preventDefault();
        }
    }
    //*--------------------------------------------------------------*
    //* FUNCTION VALID FIELD                                         *
    //*--------------------------------------------------------------*
    validField(e: any) {
        this.setState({
            credit_name: e.target.value.toUpperCase(),
        });
        if (e.target.value.indexOf(' ') >= 0) {
            this.setState({ validCredit_name: true })
            this.props.isValid(true);
        } else {
            this.setState({ validCredit_name: false })
            this.props.isValid(false);
        }
    }
    //*--------------------------------------------------------------*
    //* MAIN CLASS RENDER                                            *
    //*--------------------------------------------------------------*
    render() {
        //*----------------------------------------------------------*
        //* VALID FIELDS TO CHANGE CSS                               *
        //*----------------------------------------------------------*
        const validLabel: string = this.state.validCredit_name
            ? 'cad__form_p'
            : 'cad__form_p_error'
        const validField: string = this.state.validCredit_name
            ? 'cad__form_input'
            : 'cad__form_input_error'
        //*----------------------------------------------------------*
        //* HTML RETURN                                              *
        //*----------------------------------------------------------*
        return (
            //*------------------------------------------------------*
            //*                                                      *
            <>
                <div className='cad__paymnt_twin_text'>
                    <p className={validLabel}>{texts.paymnt__name}</p>
                    <p className='cad__form_p'>{texts.paymnt__exactaly}</p>
                </div>
                <input className={validField} required
                    name='credit_name' id='credit_name' autoComplete='off'
                    value={this.state.credit_name}
                    onPaste={(e: any) => { e.preventDefault(); }}
                    onKeyPress={(e: any) => this.keyPress(e)}
                    onChange={(e: any) => this.validField(e)}
                />
            </>
            //*                                                      *
            //*------------------------------------------------------*
        );
        //*                                                          *
        //*----------------------------------------------------------*
    }
    //*                                                              *
    //*--------------------------------------------------------------*
}
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default Payment_Name;
//*                                                                  *
//*==================================================================*   