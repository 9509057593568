//*==================================================================*
//*                                                                  *
//* EDER GUIMARAES RODRIGUES - 25/03/2022                            *
//*------------------------------------------------------------------*
//* CADBAR.TSX - BARRA DE CADASTRO PARA TELA HOME                    *
//*                                                                  *
//*------------------------------------------------------------------*
//*    IMPORTS......:                                                *
import React from 'react';
import { Link } from 'react-router-dom';
//*                                                                  *
//*------------------------------------------------------------------*
//*    CSS IMPORTS..:                                                *
import '../assets/css/Style.css';
//*                                                                  *
//*------------------------------------------------------------------*
//*    ICONS IMPORTS:                                                *
import user from '../assets/icons/user.svg';
//*                                                                  *
//*------------------------------------------------------------------*
//*    JSON IMPORTS:                                                 *
import texts from '../assets/json/texts.json';
//*                                                                  *
//*==================================================================*
//*                                                                  *
class Cadbar extends React.Component<Record<string, never>, Record<string, never>> {
    //*--------------------------------------------------------------*
    //* MAIN CLASS RENDER                                            *
    //*--------------------------------------------------------------*
    render() {
        //*----------------------------------------------------------*
        //* HTML RETURN                                              *
        //*----------------------------------------------------------*
        return (
            //*------------------------------------------------------*
            //*                                                      *
            //*------------------------------------------------------*
            <Link to={{ pathname: "/cadastro" }}>
                <section className='cadbar__area'>
                    <img src={user} />
                    <p>{texts.navbar_register}</p>
                </section>
            </Link>
            //*                                                      *
            //*------------------------------------------------------*
        );
        //*                                                          *
        //*----------------------------------------------------------*
    }
    //*                                                              *
    //*--------------------------------------------------------------*
}
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default Cadbar;
//*                                                                  *
//*==================================================================*