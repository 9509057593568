//*==================================================================*
//*                                                                  *
//* EDER GUIMARAES RODRIGUES - 25/03/2022                            *
//*------------------------------------------------------------------*
//* PAYMENT_CVV.TSX - CAMPO CVV                                      *
//*                                                                  *
//*------------------------------------------------------------------*
//*    IMPORTS......:                                                *
import React from "react";
//*                                                                  *
//*------------------------------------------------------------------*
//*    CSS IMPORTS..:                                                *
import '../../../assets/css/Style.css';
//*                                                                  *
//*------------------------------------------------------------------*
//*    JSON IMPORTS:                                                 *
import texts from '../../../assets/json/texts.json';
//*                                                                  *
//*==================================================================*
//*                                                                  *
class Payment_CVV extends React.Component<
    {
        isValid: (e: boolean) => void,
    }, {
        validCredit_cvv: boolean,
        credit_cvv: string,
    }> {
    constructor(props: any) {
        super(props);
        this.state = {
            validCredit_cvv: true,
            credit_cvv: '',
        }
    }
    //*--------------------------------------------------------------*
    //* FUNCTION KEY PRESS                                           *
    //*--------------------------------------------------------------*
    keyPress(e: any) {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    }
    //*--------------------------------------------------------------*
    //* FUNCTION VALID FIELD                                         *
    //*--------------------------------------------------------------*
    validField(e: any) {
        this.setState({
            credit_cvv: e.target.value.replace(/(\d{4})(\d)+?$/, "$1")
        })
        if (e.target.value.length < 3) {
            this.setState({ validCredit_cvv: false });
            this.props.isValid(false);
        } else {
            this.setState({ validCredit_cvv: true });
            this.props.isValid(true);
        }
    }
    //*--------------------------------------------------------------*
    //* MAIN CLASS RENDER                                            *
    //*--------------------------------------------------------------*
    render() {
        //*----------------------------------------------------------*
        //* VALID FIELDS TO CHANGE CSS                               *
        //*----------------------------------------------------------*
        const validLabel: string = this.state.validCredit_cvv
            ? 'cad__form_p'
            : 'cad__form_p_error'
        const validField: string = this.state.validCredit_cvv
            ? 'cad__form_input'
            : 'cad__form_input_error'
        //*----------------------------------------------------------*
        //* HTML RETURN                                              *
        //*----------------------------------------------------------*
        return (
            //*------------------------------------------------------*
            //*                                                      *
            <div className='form__bil-div-48'>
                <p className={validLabel}>{texts.paymnt__safe_cod}</p>
                <input className={validField} required
                    name='credit_safe' id='credit_safe' autoComplete='off'
                    value={this.state.credit_cvv}
                    onPaste={(e: any) => { e.preventDefault(); }}
                    onKeyPress={(e: any) => this.keyPress(e)}
                    onChange={(e: any) => this.validField(e)}
                />
            </div>
            //*                                                      *
            //*------------------------------------------------------*
        );
        //*                                                          *
        //*----------------------------------------------------------*
    }
    //*                                                              *
    //*--------------------------------------------------------------*
}
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default Payment_CVV;
//*                                                                  *
//*==================================================================*   