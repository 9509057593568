//*==================================================================*
//*                                                                  *
//* EDER GUIMARAES RODRIGUES - 25/03/2022                            *
//*------------------------------------------------------------------*
//* FOOTER.TSX - FOOTER DA APLICAÇÃO                                 *
//*                                                                  *
//*------------------------------------------------------------------*
//*    IMPORTS......:                                                *
import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
//*                                                                  *
//*------------------------------------------------------------------*
//*    CSS IMPORTS..:                                                *
import '../assets/css/Style.css';
//*                                                                  *
//*------------------------------------------------------------------*
//*    ICONS IMPORTS:                                                *
import logo from '../assets/icons/logo.png';
//*                                                                  *
//*------------------------------------------------------------------*
//*    JSON IMPORTS:                                                 *
import texts from '../assets/json/texts.json';
//*                                                                  *
//*==================================================================*
//*                                                                  *
class Footer extends React.Component<Record<string, never>, Record<string, never>> {
    //*--------------------------------------------------------------*
    //* MAIN CLASS RENDER                                            *
    //*--------------------------------------------------------------*
    render() {
        //*----------------------------------------------------------*
        //* HTML RETURN                                              *
        //*----------------------------------------------------------*
        return (
            //*------------------------------------------------------*
            //*                                                      *
            //*------------------------------------------------------*
            <section className='footer__area'>
                <div className='footer__logo'>
                    <img src={logo} />
                </div>
                <div className='footer__texts'>
                    <p>{texts.footer__contact}</p>

                    {/* REMOVIDO APOS REUNIAO MEET 29/03 DESCOMENTAR O CSS
                     <p>{texts.footer__phone}</p> */}

                    <p>{texts.footer__e_mail}</p>
                    <p>{texts.footer__attendance}</p>
                    <p>{texts.footer__about_me}</p>

                    <HashLink smooth to="/#dripix">
                        <p>{texts.footer__dripix}</p>
                    </HashLink>

                    <HashLink smooth to="/#comofunciona">
                        <p>{texts.footer__how_it_works}</p>
                    </HashLink>

                    <Link to={{ pathname: "/termo" }}>
                        <p>{texts.footer__terms}</p>
                    </Link>
                    <Link to={{ pathname: "/politica" }}>
                        <p>{texts.footer__privacy}</p>
                    </Link>
                    <p>{texts.footer__doubt}</p>

                    {/* REMOVIDO APOS REUNIAO MEET 29/03
                    <Link to={{ pathname: "/contato" }}>
                        <p>{texts.footer__talk_to_us}</p>
                    </Link> */}

                    <HashLink smooth to="/#faq">
                        <p>{texts.footer__faq}</p>
                    </HashLink>

                </div>
            </section>
            //*                                                      *
            //*------------------------------------------------------*
        );
        //*                                                          *
        //*----------------------------------------------------------*
    }
    //*                                                              *
    //*--------------------------------------------------------------*
}
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default Footer;
//*                                                                  *
//*==================================================================*