//*==================================================================*
//*                                                                  *
//* EDER GUIMARAES RODRIGUES - 25/03/2022                            *
//*------------------------------------------------------------------*
//* TERMO.TSX - TELA DE TERMO DA APLICAÇÃO                           *
//*                                                                  *
//*------------------------------------------------------------------*
//*    IMPORTS......:                                                *
import React from 'react';
//*                                                                  *
//*------------------------------------------------------------------*
//*    COMP. IMPORTS:                                                *
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
//*                                                                  *
//*------------------------------------------------------------------*
//*    CSS IMPORTS..:                                                *
import '../../assets/css/Style.css';
//*                                                                  *
//*------------------------------------------------------------------*
//*    JSON IMPORTS:                                                 *
import texts from '../../assets/json/texts.json';
//*                                                                  *
//*==================================================================*
//*                                                                  *
class Termo extends React.Component<Record<string, never>, Record<string, never>> {
    //*--------------------------------------------------------------*
    //* MAIN CLASS RENDER                                            *
    //*--------------------------------------------------------------*
    render() {
        //*----------------------------------------------------------*
        //* HTML RETURN                                              *
        //*----------------------------------------------------------*
        return (
            //*------------------------------------------------------*
            //*                                                      *
            //*------------------------------------------------------*
            <section className='viewport'>
                <div className='app'>
                    <Navbar />
                    <div className='term__area'>
                        <div>
                            <p className='term__title'><strong>{texts.term__title}</strong></p>
                            <p><strong>{texts.term__version}</strong></p>
                            <p><strong>{texts.term__subtitle}</strong></p>
                        </div>
                        <p><strong>{texts.term__item1_title_bold}</strong></p>
                        <p><strong>{texts.term__item1_1_bold}</strong>{texts.term__item1_1}</p>
                        <p><strong>{texts.term__item1_2_bold}</strong>{texts.term__item1_2}</p>
                        <p><strong>{texts.term__item1_3_bold}</strong>{texts.term__item1_3}</p>
                        <p><strong>{texts.term__item1_4_bold}</strong>{texts.term__item1_4}</p>
                        <p><strong>{texts.term__item1_5_bold}</strong>{texts.term__item1_5}</p>
                        <p><strong>{texts.term__item1_6_bold}</strong>{texts.term__item1_6}</p>
                        <p><strong>{texts.term__item1_7_bold}</strong>{texts.term__item1_7}</p>
                        <p><strong>{texts.term__item2_title_bold}</strong></p>
                        <p><strong>{texts.term__item2_1_bold}</strong>{texts.term__item2_1}</p>
                        <p><strong>{texts.term__item2_2_bold}</strong>{texts.term__item2_2}</p>
                        <p><strong>{texts.term__item2_3_bold}</strong>{texts.term__item2_3}</p>
                        <p><strong>{texts.term__item2_4_bold}</strong>{texts.term__item2_4}</p>
                        <p><strong>{texts.term__item2_5_bold}</strong>{texts.term__item2_5}</p>
                        <p><strong>{texts.term__item2_6_bold}</strong>{texts.term__item2_6}</p>
                        <p><strong>{texts.term__item3_title_bold}</strong></p>
                        <p><strong>{texts.term__item3_1_bold}</strong>{texts.term__item3_1}</p>
                        <p><strong>{texts.term__item3_2_bold}</strong>{texts.term__item3_2}</p>
                        <p><strong>{texts.term__item3_3_bold}</strong>{texts.term__item3_3}</p>
                        <p><strong>{texts.term__item3_4_bold}</strong>{texts.term__item3_4}</p>
                        <p><strong>{texts.term__item4_title_bold}</strong></p>
                        <p><strong>{texts.term__item4_1_bold}</strong>{texts.term__item4_1}</p>
                        <p><strong>{texts.term__item4_2_bold}</strong>{texts.term__item4_2}</p>
                        <p><strong>{texts.term__item4_3_bold}</strong>{texts.term__item4_3}</p>
                        <p><strong>{texts.term__item4_3_1_bold}</strong>{texts.term__item4_3_1}</p>
                        <p><strong>{texts.term__item5_title_bold}</strong></p>
                        <p><strong>{texts.term__item5_1_bold}</strong>{texts.term__item5_1}</p>
                        <p><strong>{texts.term__item5_2_bold}</strong>{texts.term__item5_2}</p>
                        <p><strong>{texts.term__item5_3_bold}</strong>{texts.term__item5_3}</p>
                        <p><strong>{texts.term__item5_4_bold}</strong>{texts.term__item5_4}</p>
                        <p><strong>{texts.term__item5_5_bold}</strong>{texts.term__item5_5}</p>
                        <p><strong>{texts.term__item5_5_1_bold}</strong>{texts.term__item5_5_1}</p>
                        <p><strong>{texts.term__item6_title_bold}</strong></p>
                        <p><strong>{texts.term__item6_1_bold}</strong>{texts.term__item6_1}</p>
                        <p><strong>{texts.term__item6_1_1_bold}</strong>{texts.term__item6_1_1}</p>
                        <p><strong>{texts.term__item6_2_bold}</strong>{texts.term__item6_2}</p>
                        <p><strong>{texts.term__item6_2_1_bold}</strong>{texts.term__item6_2_1}</p>
                        <p><strong>{texts.term__item6_2_2_bold}</strong>{texts.term__item6_2_2}</p>
                        <p><strong>{texts.term__item6_3_bold}</strong>{texts.term__item6_3}</p>
                        <p><strong>{texts.term__item6_3_1_bold}</strong>{texts.term__item6_3_1}</p>
                        <p><strong>{texts.term__item6_3_2_bold}</strong>{texts.term__item6_3_2}</p>
                        <p><strong>{texts.term__item7_title_bold}</strong></p>
                        <p><strong>{texts.term__item7_1_bold}</strong>{texts.term__item7_1}</p>
                        <p><strong>{texts.term__item7_2_bold}</strong>{texts.term__item7_2}</p>
                        <p><strong>{texts.term__item7_2_1_bold}</strong>{texts.term__item7_2_1}</p>
                        <p><strong>{texts.term__item7_2_2_bold}</strong>{texts.term__item7_2_2}</p>
                        <p><strong>{texts.term__item7_3_bold}</strong>{texts.term__item7_3}</p>
                        <p><strong>{texts.term__item7_4_bold}</strong>{texts.term__item7_4}</p>
                        <p><strong>{texts.term__item7_4_1_bold}</strong>{texts.term__item7_4_1}</p>
                        <p><strong>{texts.term__item8_title_bold}</strong></p>
                        <p><strong>{texts.term__item8_1_bold}</strong>{texts.term__item8_1}</p>
                        <p><strong>{texts.term__item8_2_bold}</strong>{texts.term__item8_2}</p>
                        <p><strong>{texts.term__item8_3_bold}</strong>{texts.term__item8_3}</p>
                        <p><strong>{texts.term__item9_title_bold}</strong></p>
                        <p><strong>{texts.term__item9_1_bold}</strong>{texts.term__item9_1}</p>
                        <p><strong>{texts.term__item9_1_1_bold}</strong>{texts.term__item9_1_1}</p>
                        <p><strong>{texts.term__item9_2_bold}</strong>{texts.term__item9_2}</p>
                        <p><strong>{texts.term__item9_3_bold}</strong>{texts.term__item9_3}</p>
                        <p><strong>{texts.term__item9_4_bold}</strong>{texts.term__item9_4}</p>
                        <div>
                            <p><strong>{texts.term__footer}</strong></p>
                        </div>
                    </div>
                    <Footer />
                </div >
            </section >
            //*                                                      *
            //*------------------------------------------------------*
        );
        //*                                                          *
        //*----------------------------------------------------------*
    }
    //*                                                              *
    //*--------------------------------------------------------------*
}
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default Termo;
//*                                                                  *
//*==================================================================*