//*==================================================================*
//*                                                                  *
//* EDER GUIMARAES RODRIGUES - 25/03/2022                            *
//*------------------------------------------------------------------*
//* CUSTOMER_BIRTHDATE.TSX - CAMPO NASCIMENTO                        *
//*                                                                  *
//*------------------------------------------------------------------*
//*    IMPORTS......:                                                *
import React from "react";
//*                                                                  *
//*------------------------------------------------------------------*
//*    CSS IMPORTS..:                                                *
import '../../../assets/css/Style.css';
//*                                                                  *
//*------------------------------------------------------------------*
//*    JSON IMPORTS:                                                 *
import texts from '../../../assets/json/texts.json';
//*                                                                  *
//*==================================================================*
//*                                                                  *
class Customer_Birthdate extends React.Component<
    {
        isValid: (e: boolean) => void,
    }, {
        validDate: boolean,
        date: string,
    }> {
    constructor(props: any) {
        super(props);
        this.state = {
            validDate: true,
            date: '',
        }
    }
    //*--------------------------------------------------------------*
    //* FUNCTION KEY PRESS                                           *
    //*--------------------------------------------------------------*
    keyPress(e: any) {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    }
    //*--------------------------------------------------------------*
    //* FUNCTION VALID FIELD                                         *
    //*--------------------------------------------------------------*
    validField(e: any) {
        this.setState({
            date: e.target.value.replace(/\D/g, "")
                .replace(/^(\d{2})(\d{2})(\d{4})+?$/, "$1/$2/$3")
        });
        const date = e.target.value.substring(0, 2);
        const month = e.target.value.substring(2, 4);
        const year = e.target.value.substring(4, 8);
        const currentYear = new Date().getFullYear();
        if ((e.target.value.length !== 8) || (date < 1 || date > 31) ||
            (month < 1 || month > 13) || (year <= 1902 || year > currentYear)) {
            this.setState({ validDate: false });
            this.props.isValid(false);
        } else {
            this.setState({ validDate: true });
            this.props.isValid(true);
        }
    }
    //*--------------------------------------------------------------*
    //* MAIN CLASS RENDER                                            *
    //*--------------------------------------------------------------*
    render() {
        //*----------------------------------------------------------*
        //* VALID FIELDS TO CHANGE CSS                               *
        //*----------------------------------------------------------*
        const validLabel: string = this.state.validDate
            ? 'cad__form_p'
            : 'cad__form_p_error'
        const validField: string = this.state.validDate
            ? 'cad__form_input'
            : 'cad__form_input_error'
        //*----------------------------------------------------------*
        //* HTML RETURN                                              *
        //*----------------------------------------------------------*
        return (
            //*------------------------------------------------------*
            //*                                                      *
            <div className='form__bil-div-48'>
                <p className={validLabel}>{texts.cad__birth_date}</p>
                <input className={validField} required
                    name='birth' id='birth' autoComplete='off'
                    value={this.state.date}
                    onPaste={(e: any) => { e.preventDefault(); }}
                    onKeyPress={(e: any) => this.keyPress(e)}
                    onChange={(e: any) => this.validField(e)}
                />
            </div>
            //*                                                      *
            //*------------------------------------------------------*
        );
        //*                                                          *
        //*----------------------------------------------------------*
    }
    //*                                                              *
    //*--------------------------------------------------------------*
}
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default Customer_Birthdate;
//*                                                                  *
//*==================================================================*   