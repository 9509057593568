//*==================================================================*
//*                                                                  *
//* EDER GUIMARAES RODRIGUES - 25/03/2022                            *
//* HERBERT ISHIMURA SOUSA - 21/07/2022                              *
//*------------------------------------------------------------------*
//* HOME.TSX - TELA PRINCIPAL DA APLICAÇÃO                           *
//*                                                                  *
//*------------------------------------------------------------------*
//*    IMPORTS......:                                                *
import React from 'react';
//*                                                                  *
//*------------------------------------------------------------------*
//*    COMP. IMPORTS:                                                *
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Cadbar from '../../components/Cadbar';
import CarouselDripix from '../../components/Carousel';
//*                                                                  *
//*------------------------------------------------------------------*
//*    ICONS IMPORTS:                                                *
import iot from '../../assets/icons/iot.png';
import gifts from '../../assets/icons/gifts.png';
import cad from '../../assets/icons/cad.png';
import pay from '../../assets/icons/pay.png';
//*                                                                  *
//*------------------------------------------------------------------*
//*    JSON IMPORTS:                                                 *
import texts from '../../assets/json/texts.json';
//*                                                                  *
//*------------------------------------------------------------------*
//*    CSS IMPORTS..:                                                *
import '../../assets/css/Style.css';
//*                                                                  *
//*==================================================================*
//*                                                                  *
class Home extends React.Component<Record<string, never>, Record<string, never>> {
    //*--------------------------------------------------------------*
    //* MAIN CLASS RENDER                                            *
    //*--------------------------------------------------------------*
    render() {
        //*----------------------------------------------------------*
        //* HTML RETURN                                              *
        //*----------------------------------------------------------*
        return (
            //*------------------------------------------------------*
            //*                                                      *
            //*------------------------------------------------------*
            <section className='viewport'>
                <div className='app'>
                    <Navbar />
                    <Cadbar />
                    <CarouselDripix />
                    <div className='home__how_to' id='dripix'>
                        <div className='how_to__texts'>
                            <p>{texts.home__how_to__dripix}</p>
                            <p>{texts.home__how_to__dripix_text}</p>
                            <p className='how_to__title'>{texts.carousel_benefits}</p>
                            <p id='comofunciona'>{texts.home__how_to__how}</p>
                        </div>
                        <div className='how_to__how'>
                            <img src={cad} />
                            <div>
                                <p>{texts.home__how_to__first_step}</p>
                                <p>{texts.home__how_to__register}</p>
                                <p>{texts.home__how_to__register_text}</p>
                            </div>
                        </div>
                        <div className='how_to__pay'>
                            <img src={pay} />
                            <div>
                                <p>{texts.home__how_to__second_step}</p>
                                <p>{texts.home__how_to__pay}</p>
                                <p>{texts.home__how_to__pay_text}</p>
                            </div>
                        </div>
                    </div>

                 <div className='home__oficiais'>
                    <div>
                        <p className='oficiais_title'>{texts.home_how_to_oficiais_title}</p>
                        <p className='oficiais_text_lojas'>{texts.home_how_to_oficiais_text_lojas}</p>
                        <p className='shopping'>{texts.home_how_to_oficiais_shopping01}</p>
                        <p className='shopping'>{texts.home_how_to_oficiais_shopping02}</p>
                        <p className='shopping'>{texts.home_how_to_oficiais_shopping03}</p>
                        <p className='shopping'>{texts.home_how_to_oficiais_shopping04}</p>
                        <p className='shopping'>{texts.home_how_to_oficiais_shopping05}</p>
                        <p className='shopping'>{texts.home_how_to_oficiais_shopping06}</p>
                        <p className='shopping'>{texts.home_how_to_oficiais_shopping07}</p>
                    </div>
                 </div>
                    <div className='home__benefits'>
                        <div className='benefits__texts'>
                            <p>{texts.home__benefits}</p>
                            <p>{texts.home__benefits_subtitle}</p>
                            <p>{texts.home__benefits_text1}</p>
                            <p>{texts.home__benefits_text2}</p>
                        </div>

                        <div className='benefits__imgs'>
                            <div>
                                <img src={iot} />
                            </div>
                            <div>
                                <img src={gifts} />
                            </div>
                        </div>
                    </div>

                    <div className='home__faq' id='faq'>
                        <div className='faq__texts'>
                            <p>{texts.home__faq}</p>
                            <p>{texts.home__faq_originals}</p>
                            <p>{texts.home__faq_originals_answer}</p>
                            <p>{texts.home__faq_tax}</p>
                            <p>{texts.home__faq_tax_answer}</p>
                            <p>{texts.home__faq_cancel}</p>
                            <p>{texts.home__faq_cancel_answer}</p>
                        </div>
                    </div>
                    <Footer />
                </div>
            </section>
            //*                                                      *
            //*------------------------------------------------------*
        );
        //*                                                          *
        //*----------------------------------------------------------*
    }
    //*                                                              *
    //*--------------------------------------------------------------*
}
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default Home;
//*                                                                  *
//*==================================================================*