//*==================================================================*
//*                                                                  *
//* EDER GUIMARAES RODRIGUES - 25/03/2022                            *
//*------------------------------------------------------------------*
//* ADDRESS_ADDRESS.TSX - CAMPO ENDERECO                             *
//*                                                                  *
//*------------------------------------------------------------------*
//*    IMPORTS......:                                                *
import React from "react";
//*                                                                  *
//*------------------------------------------------------------------*
//*    CSS IMPORTS..:                                                *
import '../../../assets/css/Style.css';
//*                                                                  *
//*------------------------------------------------------------------*
//*    JSON IMPORTS:                                                 *
import texts from '../../../assets/json/texts.json';
//*                                                                  *
//*==================================================================*
//*                                                                  *
class Address_Address extends React.Component<
    {
        address: string,
        isValid: (e: boolean) => void,
    }, {
        validAddress: boolean,
        address: string,
    }> {
    constructor(props: any) {
        super(props);
        this.state = {
            validAddress: true,
            address: '',
        }
    }
    componentDidMount() {
        this.setState({ address: this.props.address })
    }
    //*--------------------------------------------------------------*
    //* FUNCTION VALID FIELD                                         *
    //*--------------------------------------------------------------*
    validField(e: any) {
        this.setState({
            address: e.target.value,
        });
        if (e.target.value.length <= 0) {
            this.setState({ validAddress: false })
            this.props.isValid(false);
        } else {
            this.setState({ validAddress: true })
            this.props.isValid(true); 
        }
    }
    //*--------------------------------------------------------------*
    //* MAIN CLASS RENDER                                            *
    //*--------------------------------------------------------------*
    render() {
        //*----------------------------------------------------------*
        //* VALID FIELDS TO CHANGE CSS                               *
        //*----------------------------------------------------------*
        const validLabel: string = this.state.validAddress
            ? 'cad__form_p'
            : 'cad__form_p_error'
        const validField: string = this.state.validAddress
            ? 'cad__form_input'
            : 'cad__form_input_error'
        //*----------------------------------------------------------*
        //* HTML RETURN                                              *
        //*----------------------------------------------------------*
        return (
            //*------------------------------------------------------*
            //*                                                      *
            <div className='form__bil-div-73'>
                <p className={validLabel}>{texts.cad__address}</p>
                <input className={validField} required
                    name='address' id='address' autoComplete='off'
                    value={this.state.address}
                    onPaste={(e: any) => { e.preventDefault(); }}
                    onChange={(e: any) => this.validField(e)}
                />
            </div>
            //*                                                      *
            //*------------------------------------------------------*
        );
        //*                                                          *
        //*----------------------------------------------------------*
    }
    //*                                                              *
    //*--------------------------------------------------------------*
}
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default Address_Address;
//*                                                                  *
//*==================================================================*   