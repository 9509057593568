//*==================================================================*
//*                                                                  *
//* EDER GUIMARAES RODRIGUES - 25/03/2022                            *
//*------------------------------------------------------------------*
//* PAYMENT_VALIDITY.TSX - CAMPO VALIDADE                            *
//*                                                                  *
//*------------------------------------------------------------------*
//*    IMPORTS......:                                                *
import React from "react";
//*                                                                  *
//*------------------------------------------------------------------*
//*    CSS IMPORTS..:                                                *
import '../../../assets/css/Style.css';
//*                                                                  *
//*------------------------------------------------------------------*
//*    JSON IMPORTS:                                                 *
import texts from '../../../assets/json/texts.json';
//*                                                                  *
//*==================================================================*
//*                                                                  *
class Payment_Validity extends React.Component<
    {
        isValid: (e: boolean) => void,
    }, {
        validCredit_validity: boolean,
        credit_validity: string,
    }> {
    constructor(props: any) {
        super(props);
        this.state = {
            validCredit_validity: true,
            credit_validity: '',
        }
    }
    //*--------------------------------------------------------------*
    //* FUNCTION KEY PRESS                                           *
    //*--------------------------------------------------------------*
    keyPress(e: any) {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    }
    //*--------------------------------------------------------------*
    //* FUNCTION VALID FIELD                                         *
    //*--------------------------------------------------------------*
    validField(e: any) {
        const number = e.target.value.replace(/[^0-9]+/g, '');
        this.setState({
            credit_validity: e.target.value.replace(/\D/g, "").replace(/^(\d{2})(\d{2})+?$/, "$1/$2")
        })
        if (number.substr(0, 2) < 1 || number.substr(0, 2) > 12) {
            this.setState({ validCredit_validity: false });
            this.props.isValid(false);
        } else {
            if (number.substr(2, 4) < 22) {
                this.setState({ validCredit_validity: false });
                this.props.isValid(false);
            } else {
                this.setState({ validCredit_validity: true });
                this.props.isValid(true);
            }
        }
    }
    //*--------------------------------------------------------------*
    //* MAIN CLASS RENDER                                            *
    //*--------------------------------------------------------------*
    render() {
        //*----------------------------------------------------------*
        //* VALID FIELDS TO CHANGE CSS                               *
        //*----------------------------------------------------------*
        const validLabel: string = this.state.validCredit_validity
            ? 'cad__form_p'
            : 'cad__form_p_error'
        const validField: string = this.state.validCredit_validity
            ? 'cad__form_input'
            : 'cad__form_input_error'
        //*----------------------------------------------------------*
        //* HTML RETURN                                              *
        //*----------------------------------------------------------*
        return (
            //*------------------------------------------------------*
            //*                                                      *
            <div className='form__bil-div-23'>
                <p className={validLabel}>{texts.paymnt__validity}</p>
                <input className={validField} required
                    name='credit_validity' id='credit_validity' autoComplete='off'
                    value={this.state.credit_validity}
                    onPaste={(e: any) => { e.preventDefault(); }}
                    onKeyPress={(e: any) => this.keyPress(e)}
                    onChange={(e: any) => this.validField(e)}
                />
            </div>
            //*                                                      *
            //*------------------------------------------------------*
        );
        //*                                                          *
        //*----------------------------------------------------------*
    }
    //*                                                              *
    //*--------------------------------------------------------------*
}
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default Payment_Validity;
//*                                                                  *
//*==================================================================*   