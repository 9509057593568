//*==================================================================*
//*                                                                  *
//* EDER GUIMARAES RODRIGUES - 25/03/2022                            *
//*------------------------------------------------------------------*
//* ROUTER.TSX - ARQUIVO DE ROTAS DO DRIPIX                          *
//*                                                                  *
//*------------------------------------------------------------------*
//*    PAGES IMPORTS:                                                *
//*    IMPORTS......:                                                *
import { Route, Routes, BrowserRouter } from "react-router-dom";
//*                                                                  *
//*------------------------------------------------------------------*
//*    PAGES IMPORTS:                                                *
import Home from "../pages/home/Home";
import Cadastro from "../pages/cadastro/Cadastro";
import Menu from "../pages/menu/Menu";
import Termo from "../pages/termo/Termo";
import Politica from "../pages/politica/Politica";
//*                                                                  *
//*==================================================================*
//*                                                                  *
const RoutesDripix = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/cadastro" element={<Cadastro />} />
                <Route path="/menu" element={<Menu />} />
                <Route path="/termo" element={<Termo />} />
                <Route path="/politica" element={<Politica />} />
            </Routes>
        </BrowserRouter>
        //*-----------------------------------------------------------*
    );
    //*--------------------------------------------------------------*
}
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default RoutesDripix;
//*                                                                  *
//*==================================================================*