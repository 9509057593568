//*==================================================================*
//*                                                                  *
//* EDER GUIMARAES RODRIGUES - 25/03/2022                            *
//*------------------------------------------------------------------*
//* ADDRESS_STATE.TSX - CAMPO ESTADO                                 *
//*                                                                  *
//*------------------------------------------------------------------*
//*    IMPORTS......:                                                *
import React from "react";
//*                                                                  *
//*------------------------------------------------------------------*
//*    CSS IMPORTS..:                                                *
import '../../../assets/css/Style.css';
//*                                                                  *
//*------------------------------------------------------------------*
//*    JSON IMPORTS:                                                 *
import texts from '../../../assets/json/texts.json';
//*                                                                  *
//*==================================================================*
//*                                                                  *
class Address_State extends React.Component<
    {
        state: string,
        isValid: (e: boolean) => void,
    }, {
        validState: boolean,
        state: string,
    }> {
    constructor(props: any) {
        super(props);
        this.state = {
            validState: true,
            state: '',
        }
    }
    componentDidMount() {
        this.setState({ state: this.props.state })
    }
    //*--------------------------------------------------------------*
    //* FUNCTION KEY PRESS                                           *
    //*--------------------------------------------------------------*
    keyPress(e: any) {
        if (/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    }
    //*--------------------------------------------------------------*
    //* FUNCTION VALID FIELD                                         *
    //*--------------------------------------------------------------*
    validField(e: any) {
        this.setState({
            state: e.target.value,
        });
        if (e.target.value.length <= 0) {
            this.setState({ validState: false })
            this.props.isValid(false);
        } else {
            this.setState({ validState: true })
            this.props.isValid(true);
        }
    }
    //*--------------------------------------------------------------*
    //* MAIN CLASS RENDER                                            *
    //*--------------------------------------------------------------*
    render() {
        //*----------------------------------------------------------*
        //* VALID FIELDS TO CHANGE CSS                               *
        //*----------------------------------------------------------*
        const validLabel: string = this.state.validState
            ? 'cad__form_p'
            : 'cad__form_p_error'
        const validField: string = this.state.validState
            ? 'cad__form_input'
            : 'cad__form_input_error'
        //*----------------------------------------------------------*
        //* HTML RETURN                                              *
        //*----------------------------------------------------------*
        return (
            //*------------------------------------------------------*
            //*                                                      *
            <div className='form__bil-div-23'>
                <p className={validLabel}>{texts.cad__state}</p>
                <input className={validField} required
                    name='state' id='state' autoComplete='off'
                    value={this.state.state}
                    onPaste={(e: any) => { e.preventDefault(); }}
                    onKeyPress={(e: any) => this.keyPress(e)}
                    onChange={(e: any) => this.validField(e)}
                />
            </div>
            //*                                                      *
            //*------------------------------------------------------*
        );
        //*                                                          *
        //*----------------------------------------------------------*
    }
    //*                                                              *
    //*--------------------------------------------------------------*
}
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default Address_State;
//*                                                                  *
//*==================================================================*   