//*==================================================================*
//*                                                                  *
//* EDER GUIMARAES RODRIGUES - 25/03/2022                            *
//*------------------------------------------------------------------*
//* TERMO.TSX - TELA DE TERMO DA APLICAÇÃO                           *
//*                                                                  *
//*------------------------------------------------------------------*
//*    IMPORTS......:                                                *
import React from 'react';
//*                                                                  *
//*------------------------------------------------------------------*
//*    COMP. IMPORTS:                                                *
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
//*                                                                  *
//*------------------------------------------------------------------*
//*    CSS IMPORTS..:                                                *
import '../../assets/css/Style.css';
//*                                                                  *
//*------------------------------------------------------------------*
//*    JSON IMPORTS:                                                 *
import texts from '../../assets/json/texts.json';
//*                                                                  *
//*==================================================================*
//*                                                                  *
class Politica extends React.Component<Record<string, never>, Record<string, never>> {
    //*--------------------------------------------------------------*
    //* MAIN CLASS RENDER                                            *
    //*--------------------------------------------------------------*
    render() {
        //*----------------------------------------------------------*
        //* HTML RETURN                                              *
        //*----------------------------------------------------------*
        return (
            //*------------------------------------------------------*
            //*                                                      *
            //*------------------------------------------------------*
            <section className='viewport'>
                <div className='app'>
                    <Navbar />
                    <div className='polit__area'>
                        <div>
                            <p className='polit__title'><strong>{texts.polit__title}</strong></p>
                            <p><strong>{texts.polit__subtitle}</strong></p>
                        </div>
                        <p><strong>{texts.polit__item1_bold}</strong></p>
                        <p>{texts.polit__item1_1}</p>
                        <p>{texts.polit__item1_2}</p>
                        <p>{texts.polit__item1_3}</p>
                        <p>{texts.polit__item1_3_a}</p>
                        <p>{texts.polit__item1_3_b}</p>
                        <p>{texts.polit__item1_3_c}</p>
                        <p>{texts.polit__item1_4}</p>
                        <p><strong>{texts.polit__item2_bold}</strong></p>
                        <p>{texts.polit__item2_1}</p>
                        <p>{texts.polit__item2_2}</p>
                        <p>{texts.polit__item2_3}</p>
                        <p>{texts.polit__item2_4}</p>
                        <p>{texts.polit__item2_5}</p>
                        <p>{texts.polit__item2_6}</p>
                        <p>{texts.polit__item2_7}</p>
                        <p>{texts.polit__item2_8}</p>
                        <p>{texts.polit__item2_9}</p>
                        <p>{texts.polit__item2_10}</p>
                        <p>{texts.polit__item2_11}</p>
                        <p><strong>{texts.polit__item3_bold}</strong></p>
                        <p>{texts.polit__item3_1}</p>
                        <p>{texts.polit__item3_2}</p>
                        <p><strong>{texts.polit__item4_bold}</strong></p>
                        <p>{texts.polit__item4_1}</p>
                        <p><strong>{texts.polit__item5_bold}</strong></p>
                        <p>{texts.polit__item5_1}</p>
                        <p>{texts.polit__item5_2}</p>
                        <p>{texts.polit__item5_3}</p>
                        <p>{texts.polit__item5_4}</p>
                        <p>{texts.polit__item5_5}</p>
                        <p><strong>{texts.polit__item6_bold}</strong></p>
                        <p>{texts.polit__item6_1}</p>
                        <p>{texts.polit__item6_2}</p>
                        <p>{texts.polit__item6_3}</p>
                        <p>{texts.polit__item6_4}</p>
                        <p>{texts.polit__item6_5}</p>
                        <p>{texts.polit__item6_6}</p>
                        <p>{texts.polit__item6_7}</p>
                        <p>{texts.polit__item6_8}</p>
                        <p>{texts.polit__item6_9}</p>
                        <p>{texts.polit__item6_10}</p>
                        <p><strong>{texts.polit__item7_bold}</strong></p>
                        <p>{texts.polit__item7_1}</p>
                        <p>{texts.polit__item7_2}</p>
                        <p>{texts.polit__item7_3}</p>
                        <p>{texts.polit__item7_4}</p>
                        <p>{texts.polit__item7_5}</p>
                        <p>{texts.polit__item7_6}</p>
                        <p>{texts.polit__item7_7}</p>
                        <p>{texts.polit__item7_8}</p>
                        <p>{texts.polit__item7_9}</p>
                        <p>{texts.polit__item7_10}</p>
                        <p>{texts.polit__item7_11}</p>
                        <p><strong>{texts.polit__item8_bold}</strong></p>
                        <p>{texts.polit__item8_1}</p>
                        <p>{texts.polit__item8_2}</p>
                        <p>{texts.polit__item8_3}</p>
                        <p><strong>{texts.polit__item9_bold}</strong></p>
                        <p>{texts.polit__item9_1}</p>
                        <p>{texts.polit__item9_2}</p>
                        <p>{texts.polit__item9_3}</p>
                        <p><strong>{texts.polit__item10_bold}</strong></p>
                        <p>{texts.polit__item10_1}</p>
                        <p><strong>{texts.polit__item11_bold}</strong></p>
                        <p>{texts.polit__item11_1}</p>
                        <p><strong>{texts.polit__item12_bold}</strong></p>
                        <p>{texts.polit__item12_1}</p>
                        <p>{texts.polit__item12_2}</p>
                        <p>{texts.polit__item12_3}</p>
                        <p><strong>{texts.polit__item13_bold}</strong></p>
                        <p>{texts.polit__item13_1}</p>
                    </div>
                    <Footer />
                </div >
            </section >
            //*                                                      *
            //*------------------------------------------------------*
        );
        //*                                                          *
        //*----------------------------------------------------------*
    }
    //*                                                              *
    //*--------------------------------------------------------------*
}
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default Politica;
//*                                                                  *
//*==================================================================*