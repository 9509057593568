//*==================================================================*
//*                                                                  *
//* EDER GUIMARAES RODRIGUES - 25/03/2022                            *
//*------------------------------------------------------------------*
//* CUSTOMER_PHONE.TSX - CAMPO TELEFONE                              *
//*                                                                  *
//*------------------------------------------------------------------*
//*    IMPORTS......:                                                *
import React from "react";
//*                                                                  *
//*------------------------------------------------------------------*
//*    CSS IMPORTS..:                                                *
import '../../../assets/css/Style.css';
//*                                                                  *
//*------------------------------------------------------------------*
//*    JSON IMPORTS:                                                 *
import texts from '../../../assets/json/texts.json';
//*                                                                  *
//*==================================================================*
//*                                                                  *
class Customer_Phone extends React.Component<
    {
        isValid: (e: boolean) => void,
    }, {
        validPhone: boolean,
        phone: string,
    }> {
    constructor(props: any) {
        super(props);
        this.state = {
            validPhone: true,
            phone: '',
        }
    }
    //*--------------------------------------------------------------*
    //* FUNCTION KEY PRESS                                           *
    //*--------------------------------------------------------------*
    keyPress(e: any) {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    }
    //*--------------------------------------------------------------*
    //* FUNCTION VALID FIELD                                         *
    //*--------------------------------------------------------------*
    validField(e: any) {
        this.setState({
            phone: e.target.value.replace(/\D/g, "")
                .replace(/^(\d{2})(\d{4,5})(\d{4})+?$/, "($1) $2-$3")
        });
        const phoneLength = e.target.value.replace(/[^0-9]+/g, '').length;
        if (phoneLength < 10 || phoneLength > 11) {
            this.setState({ validPhone: false });
            this.props.isValid(false);
        } else {
            this.setState({ validPhone: true });
            this.props.isValid(true);
        }
    }
    //*--------------------------------------------------------------*
    //* MAIN CLASS RENDER                                            *
    //*--------------------------------------------------------------*
    render() {
        //*----------------------------------------------------------*
        //* VALID FIELDS TO CHANGE CSS                               *
        //*----------------------------------------------------------*
        const validLabel: string = this.state.validPhone
            ? 'cad__form_p'
            : 'cad__form_p_error'
        const validField: string = this.state.validPhone
            ? 'cad__form_input'
            : 'cad__form_input_error'
        //*----------------------------------------------------------*
        //* HTML RETURN                                              *
        //*----------------------------------------------------------*
        return (
            //*------------------------------------------------------*
            //*                                                      *
            <div className='form__bil-div-48'>
                <p className={validLabel}>{texts.cad__phone}</p>
                <input className={validField} required
                    name='phone' id='phone' autoComplete='off'
                    value={this.state.phone}
                    onPaste={(e: any) => { e.preventDefault(); }}
                    onKeyPress={(e: any) => this.keyPress(e)}
                    onChange={(e: any) => this.validField(e)}
                />
            </div>
            //*                                                      *
            //*------------------------------------------------------*
        );
        //*                                                          *
        //*----------------------------------------------------------*
    }
    //*                                                              *
    //*--------------------------------------------------------------*
}
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default Customer_Phone;
//*                                                                  *
//*==================================================================*   