//*==================================================================*
//*                                                                  *
//* EDER GUIMARAES RODRIGUES - 25/03/2022                            *
//*------------------------------------------------------------------*
//* ADDRESS_CEP.TSX - CAMPO CEP                                      *
//*                                                                  *
//*------------------------------------------------------------------*
//*    IMPORTS......:                                                *
import axios from "axios";
import React from "react";
//*                                                                  *
//*------------------------------------------------------------------*
//*    CSS IMPORTS..:                                                *
import '../../../assets/css/Style.css';
//*                                                                  *
//*------------------------------------------------------------------*
//*    JSON IMPORTS:                                                 *
import texts from '../../../assets/json/texts.json';
//*                                                                  *
//*==================================================================*
//*                                                                  *
class Address_CEP extends React.Component<
    {
        isValid: (e: boolean) => void,
        zipValid: (e: any) => void,
    }, {
        validZip: boolean,
        zip: string,
    }> {
    constructor(props: any) {
        super(props);
        this.state = {
            validZip: true,
            zip: '',
        }
    }
    //*--------------------------------------------------------------*
    //* FUNCTION KEY PRESS                                           *
    //*--------------------------------------------------------------*
    keyPress(e: any) {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    }
    //*--------------------------------------------------------------*
    //* FUNCTION VALID FIELD                                         *
    //*--------------------------------------------------------------*
    async validField(e: any) {
        this.setState({
            zip: e.target.value.replace(/\D/g, "")
                .replace(/^(\d{5})(\d{3})+?$/, "$1-$2")
        });
        const zipNumbers = e.target.value.replace(/[^0-9]+/g, '');
        if (zipNumbers.length === 8) {
            try {
                const res = await axios.get('https://viacep.com.br/ws/' + zipNumbers + '/json')
                if (!res.data.erro) {
                    this.props.zipValid({
                        "address": res.data.logradouro,
                        "neighbor": res.data.bairro,
                        "city": res.data.localidade,
                        "uf": res.data.uf
                    });
                    this.setState({ validZip: true });
                    this.props.isValid(true)
                } else {
                    this.setState({ validZip: false });
                    this.props.isValid(false)
                }
            }
            catch (error) {
                console.log(error)
            }
        } else {
            this.setState({ validZip: false });
            this.props.isValid(false)
        }
    }
    //*--------------------------------------------------------------*
    //* MAIN CLASS RENDER                                            *
    //*--------------------------------------------------------------*
    render() {
        //*----------------------------------------------------------*
        //* VALID FIELDS TO CHANGE CSS                               *
        //*----------------------------------------------------------*
        const validLabel: string = this.state.validZip
            ? 'cad__form_p'
            : 'cad__form_p_error'
        const validField: string = this.state.validZip
            ? 'cad__form_input'
            : 'cad__form_input_error'
        //*----------------------------------------------------------*
        //* HTML RETURN                                              *
        //*----------------------------------------------------------*
        return (
            //*------------------------------------------------------*
            //*                                                      *
            <div className='form__bil-div-48'>
                <p className={validLabel}>{texts.cad__cep}</p>
                <input className={validField} required
                    name='zip' id='zip' autoComplete='off'
                    value={this.state.zip}
                    onPaste={(e: any) => { e.preventDefault(); }}
                    onKeyPress={(e: any) => this.keyPress(e)}
                    onChange={(e: any) => this.validField(e)}
                />
            </div>
            //*                                                      *
            //*------------------------------------------------------*
        );
        //*                                                          *
        //*----------------------------------------------------------*
    }
    //*                                                              *
    //*--------------------------------------------------------------*
}
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default Address_CEP;
//*                                                                  *
//*==================================================================*   